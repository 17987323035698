import {useState, useEffect} from 'react';
import {
  Button,
  CustomDialog,
  DCLogoTypes,
  Image,
  Input,
  Logo,
  Typography,
} from '../ui';
import {X} from 'lucide-react';
import {useResponsive} from '~/hooks';
import { ReadyMadeMealsBanner } from '~/assets/images';

export function Newsletter() {
  const newsletter = DUMMY;
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const {isMobileOnly} = useResponsive();

  const handleClose = (): void => setIsOpen(false);

  const saveEmail = (): void => {
    if (!isValid) return;
    document.cookie = `emailNewsletter=${inputValue}; SameSite=None; Secure`;
    handleClose();
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
    const hexEmailPattern = /\S+@\S+\.\S+/;

    setIsValid(hexEmailPattern.test(event.target.value));
  };

  const iconProps = {
    color: isMobileOnly ? '#fff' : '#b4b4b4',
    fill: isMobileOnly ? '#fff' : '#b4b4b4',
    size: isMobileOnly ? 35 : 40,
    strokeWidth: 2,
  };

  useEffect(() => {
    const cookieEmail = document.cookie
      .split('; ')
      .find((row) => row.startsWith('emailNewsletter='))
      ?.split('=')[1];
    if (!cookieEmail) {
      setTimeout(() => {
        //setIsOpen(true);
      }, 1500);
    }
  }, []);

  return (
    <>
      <CustomDialog isOpen={isOpen} onClose={handleClose}>
        <div className="flex h-full relative">
          <X
            {...iconProps}
            className="absolute top-4 right-4 cursor-pointer"
            onClick={handleClose}
          />
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 md:aspect-w-2 md:aspect-h-1">
              <Image
                src={newsletter.scr}
                alt="Newsletter"
                className="w-full h-52 sm:h-72 md:w-auto md:h-full object-cover object-center"
                style={{
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
            </div>
            <div className="md:w-1/2 md:aspect-w-1 md:aspect-h-1 items-center text-center px-3 sm:px-8 md:px-12 py-5 ms:py-5 md:py-8">
              <Logo
                className="mx-auto "
                type={DCLogoTypes.FullColorHorizontal}
                size={200}
              />
              <div className="text-cool_blue">
                <Typography
                  variant="headline1"
                  className="leading-[20px] sm:leading-[41px] md:leading-[100px]"
                >
                  {newsletter.percentage + ' % off'}
                </Typography>
              </div>
              <div className="mb-2 sm:mb-5">
                <Typography variant="headline6">{newsletter.title}</Typography>
                <Typography
                  variant="headline5"
                  className="text-cool_blue mb-1 sm:mb-4"
                >{`Gone in < ${newsletter.time} hours!`}</Typography>
                <Typography variant="body1">{newsletter.text}</Typography>
              </div>
              <div className="flex flex-col">
                <Input
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Email Address"
                  className={`${isValid ? '' : 'border-red-700'}`}
                />
                <Button variant="cool" onClick={saveEmail}>
                  Sign Up Now
                </Button>
              </div>
              <Typography variant="overline">
                {newsletter.conditions}
              </Typography>
            </div>
          </div>
        </div>
      </CustomDialog>
    </>
  );
}

const DUMMY = {
  scr: ReadyMadeMealsBanner,
  percentage: 30,
  title: `Our Limited Time for Fresh Vegetables`,
  time: 12,
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labor.',
  conditions:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor adipiscing elit, sed do eiusmod tempor.',
};
