import {VendorModel} from '~/models';
import {Image, Stars, Typography} from '../ui';
import placeholder from '~/assets/images/placeholder.png';
import {cn} from '~/lib/utils';
import { Link, useNavigate } from '@remix-run/react';
interface BrandProps {
  vendor: VendorModel;
  className?: string;
  link?: string;
}

export function Brand({vendor, className, link}: BrandProps) {
  const image = vendor.logo.length > 0 ? vendor.logo[0].src : '';
  return (
    <Link
      to={link || ''}
      reloadDocument={true}
      prefetch="intent"
      className={cn(
        'rounded-lg border-gray-200 border-[1px] p-4 my-5 ml-4 items-center h-auto md:h-72 block !no-underline hover:shadow-lg hover:shadow-gray-300 duration-300 hover:scale-105 hover:z-10',
        className,
      )}
      key={`brand-${vendor.id}`}
    >
      <figure className="flex items-center justify-center h-20 md:h-36 w-full mb-2 ">
        <Image
          src={image || placeholder}
          alt={`Vendor-${vendor.companyName}`}

          className="rounded-lg max-h-20 md:max-h-36 w-auto h-auto mx-auto my-auto"
        />
      </figure>
      <Typography
        className="text-center mb-0 !no-underline truncate mt-4"
        variant="headline6"
      >
        {vendor.companyName}
      </Typography>
      <Stars rating={vendor.rating?.value || 0} className="mb-3" />
      {/* <Typography className="text-center !no-underline" variant="body2">
        {`${vendor.rating?.reviewCount || 'No'} Reviews`}
      </Typography> */}
    </Link>
  );
}
