import {Typography, Carousel } from '../ui';
import {VendorModel} from '~/models';
import {Brand} from './Brand.component';
import { useMediaQuery } from 'react-responsive';
interface BrandsGalleryProps {
  vendors: Array<VendorModel>;
  description: string;
  className?: string;
  childClassName?: string;
}
export function BrandsGallery({vendors, description, className, childClassName}: BrandsGalleryProps) {
  const isMobileOnly = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <div className="mt-20 mb-16" id='FeatureBrands'>
      <div className="mb-10">
      <Typography variant="headline3" type='h2'>
        Featured Brands
      </Typography>
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: description }} className="my-4"/>
      </div>
      {vendors && (
        <Carousel
          slidesPerView={4}
          spaceBetween={15}
          autoplay
          loop
          useBreakpoints
          previewNextSlide={0.125}
          centeredSlides={isMobileOnly}
        >
          {vendors.map((vendor, i) => (
            <Brand
              vendor={vendor}
              link={`/seller/${vendor.handle}/products`}
              key={`brand-${i}`}
              className={childClassName}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
}
