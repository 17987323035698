import type {V2_MetaFunction} from '@shopify/remix-oxygen';
import {defer, type LoaderArgs} from '@shopify/remix-oxygen';
import {Await, useLoaderData, Link} from '@remix-run/react';
import {Suspense, useEffect, useState} from 'react';
import {Image, Money} from '@shopify/hydrogen';
import type {
  FeaturedCollectionFragment,
  RecommendedProductsQuery,
} from 'storefrontapi.generated';
import {graphQLService} from '~/services';
import {
  productsByCollectionHandle,
  GetBrands,
  getAllCategoriesSimplified,
} from '~/graphql/queries';
import {
  CategoryModel,
  PaginatedVendor,
  ProductByCollectionHandle,
} from '~/models';
import {ProductDeals} from '~/components/products';
import {CategoriesGallery} from '~/components/categories';
import {BrandsGallery} from '~/components/brands';
import {BestOfType} from '~/components/bestof';

/** TODO: Get this info from API */
const BEST_OF_GALLERY: Array<BestOfType> = [
  {
    title: 'Rancher Harvest',
    id: `1`,
    url: 'https://cdn.shopify.com/s/files/1/0793/2693/7364/collections/beef_snacks-cut-water.jpg',
  },
  {
    title: 'Delivered cold',
    id: `2`,
    url: 'https://cdn.shopify.com/s/files/1/0793/2693/7364/collections/cut-out-half-b.jpg',
  },
  {
    title: 'Frozen logistics',
    id: `3`,
    url: 'https://cdn.shopify.com/s/files/1/0793/2693/7364/collections/performanc.jpg',
  },
  {
    title: 'Food link, frozen meat',
    id: `4`,
    url: 'https://cdn.shopify.com/s/files/1/0793/2693/7364/collections/beef_snacks-cut-water.jpg',
  },
];

import {
  BannerContentDiv,
  BannerImageSide,
  BannerTextSide,
} from '~/components/ui/Banner.component';
import {LandingFeature, LandingFeatureModel} from '~/components/landing';
import {FadeInSection,Typography,Button} from '~/components/ui';
import {Newsletter} from '~/components/Newsletter';
import { convertProductsHandleToGenericList } from '~/factories/convertProductsToGeneric.factory';

const metaTitle = 'Frozen Food Delivery | Grocery Delivery Box | Delivered Cold'
export const meta: V2_MetaFunction = ({data}) => {
  const {appName} = data;
  return [
    {title: metaTitle ? metaTitle :`${ appName ? appName + " | " : "" }Home`},
    {
      name: "description",
      content: "Get frozen food delivery straight to your doorstep. Explore a world of convenience and high-quality foods without having to buy in bulk. Order at Delivered Cold!",
    },
];
};

export async function loader({context}: LoaderArgs) {
  const {storefront} = context;
  const {collections} = await storefront.query(FEATURED_COLLECTION_QUERY);
  const featuredCollection = collections.nodes[0];
  const recommendedProducts = storefront.query(RECOMMENDED_PRODUCTS_QUERY);

  const {getGraphQL} = graphQLService(context);
  const dailyDeals = getGraphQL<ProductByCollectionHandle>(
    productsByCollectionHandle,
    {
      handle: 'daily',
    },
  ).then((result) => convertProductsHandleToGenericList(result));

  const monthlyHighlights = getGraphQL<ProductByCollectionHandle>(
    productsByCollectionHandle,
    {
      handle: 'monthly',
    },
  ).then((result) => convertProductsHandleToGenericList(result));

  const {categories} = await getGraphQL<{categories: Array<CategoryModel>}>(getAllCategoriesSimplified);
  const brands = await getGraphQL<PaginatedVendor>(GetBrands);

  const banner = {
    id: 'CAT-9754',
    name: 'Ready made meals, from our freezer to yours',
    description:
      `Life can get busy, and preparing meals from scratch isn't always an option. Our ready-made meals are the perfect solution. These meals, carefully crafted ane selected for your convenience, go from our freezer to yours. Just heat and enjoy.`,
    image:
      'https://cdn.shopify.com/s/files/1/0793/2693/7364/collections/cut-out-half-b.jpg?v=1691093262&crop=center',
  };

  const homepageContent = {
    title : 'Delivered Cold: Online Frozen Food Delivery',
    description: `Delivered Cold is your premier destination for frozen food delivery. Order and fill up your own grocery delivery box with high-quality frozen foods. No need to order in bulk. Our advanced algorithm will even suggest items for you that will fill up your box, so you can get the greatest value per box.`
  }

  const contentAboveBannerContent = {
    title : 'Explore Our Marketplace',
    description: `With a minimum order of just $99, our Delivered Cold boxes empower you to curate your frozen food selection from a diverse range of premium and unique brands. Our algorithm maximizes your box by suggesting products based upon available space. We're not just a marketplace; we're a commitment to sustainability, planting a tree for every box we ship. Experience the future of frozen food delivery, tailored for you. Buy now and enjoy quality, variety, and eco-consciousness frozen food delivery.`
  }

  const landingBanners = getLandingBanners(context.env);

  return defer({
    featuredCollection,
    recommendedProducts,
    dailyDeals,
    monthlyHighlights,
    categories,
    brands,
    banner,
    landingBanners,
    appName: context.env.APP_NAME,
    homepageContent,
    contentAboveBannerContent,
    siteURL: context.env.SITE_URL,
    facebookURL: context.env.FACEBOOK,
    linkedinURL: context.env.LINKEDIN,
  });
}

const getLandingBanners = (env: Env): Array<LandingFeatureModel> => [
  {
    title: env.LANDING_BANNER_1_TITLE,
    description: env.LANDING_BANNER_1_DESCRIPTION,
    scr: env.LANDING_BANNER_1_IMAGESRC,
    to: env.LANDING_BANNER_1_ACTION_TO,
    buttonText: env.LANDING_BANNER_1_BUTTON
  },
  {
    title: env.LANDING_BANNER_2_TITLE,
    description: env.LANDING_BANNER_2_DESCRIPTION,
    scr: env.LANDING_BANNER_2_IMAGESRC,
    to: env.LANDING_BANNER_2_ACTION_TO,
    buttonText: env.LANDING_BANNER_2_BUTTON
  },
  {
    title: env.LANDING_BANNER_3_TITLE,
    description: env.LANDING_BANNER_3_DESCRIPTION,
    scr: env.LANDING_BANNER_3_IMAGESRC,
    to: env.LANDING_BANNER_3_ACTION_TO,
    buttonText: env.LANDING_BANNER_3_BUTTON
  }
];

export default function Homepage() {
  const data = useLoaderData<typeof loader>();
  const {brands, banner, landingBanners, homepageContent, contentAboveBannerContent, siteURL, facebookURL, linkedinURL } = data;
  const {vendors} = brands;
  const [isOrgSchema, setIsOrgSchema] = useState(false);

  useEffect(()=>{
    setIsOrgSchema(true);
    console.info("new release for swiper upgrade library.")
  },[])

  return (
    <div className="home">
      {/* adding ORG schema starts */}
      {isOrgSchema ? 
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Frozen Logistics",
            "url": "${siteURL}",
            "logo": "https://cdn.shopify.com/oxygen-v2/28062/13937/28717/220034/build/_assets/Color_Horizontal-BBTOMG3A.png",
            "sameAs": [
              "${facebookURL}",
              "${linkedinURL}",
              "${siteURL}"
            ]
          }`}
        </script>
      : ''}
      {/* adding ORG scehma ends */}

      <FadeInSection >
        <LandingFeature banners={landingBanners} id='howItworks'/>
      </FadeInSection>
      
      <div className="px-nav">
          <div
            style={{display: 'flex'}}
            className="flex-col xl:flex-row"
          >
            <div className="flex flex-col justify-center gap-y-5">
              <div>
                <Typography variant="headline2" type='h1'>
                  {homepageContent.title}
                </Typography>
                { homepageContent.description ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: homepageContent.description }} /> : '' } 
                <Button
                  className="px-10 md:px-6 !no-underline text-white mt-4"
                  variant="cool"
                  to='/categories'
                >
                  <Typography variant="button">Order Now</Typography>
                </Button>
              </div>
            </div>
          </div>
          <FadeInSection>
            <CategoriesGallery
              categories={data.categories}
            />
          </FadeInSection>
          <FadeInSection>
            <ProductDeals
              products={data.dailyDeals}
              title="Daily Deals"
              description="Stay tuned to our daily deals section for fantastic offers on a range of frozen products, available for hassle-free frozen food delivery and ready to be added to your grocery delivery box. At Delivered Cold, we're committed to making high-quality frozen goods affordable. With our daily discounts, you can enjoy the best without breaking the bank. Your wallet and taste buds are in for a treat!"
              id='DailyDeals'
            />
          </FadeInSection>
          <FadeInSection >
            <div
              style={{display: 'flex'}}
              className="flex-col xl:flex-row mt-20"
            >
              <div className="flex flex-col justify-center gap-y-5">
                <div>
                  <Typography variant="headline3" type='h2'>
                    {contentAboveBannerContent.title}
                  </Typography>
                  { contentAboveBannerContent.description ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: contentAboveBannerContent.description }} /> : '' } 
                </div>
              </div>
            </div>
          </FadeInSection>
          <FadeInSection>
            <BannerContentDiv className="grid sm:grid-cols-2 grid-cols-1 content-center mt-6">
              <BannerTextSide
                title={banner.name}
                text={banner.description}
                className={
                  'place-content-center items-center bg-slate-900 text-white text-center md:text-left px-8 xl:px-20'
                }
                classNameHeadline="mb-8 lg:mb-14"
              ></BannerTextSide>
              <BannerImageSide url={banner.image}></BannerImageSide>
            </BannerContentDiv>
          </FadeInSection>
          <FadeInSection>
            <ProductDeals
              products={data.monthlyHighlights}
              title="Monthly Highlights"
              description="Stay in the know with our monthly highlights, your gateway to the hottest trends and must-have frozen items, all available for seamless frozen food delivery and easily added to your mail order food boxes. We carefully curate a selection of products that are currently stealing the spotlight in the world of frozen goods. Dive in and discover what's making waves this month!"
              id='MonthlyHighlights'
            />
          </FadeInSection>
          {/* TODO: Uncomment when the service return the best of gallery
          <FadeInSection>
            <BestOfGallery
              leadingItems={BEST_OF_GALLERY}
              className="mt-20 h-full"
            />
          </FadeInSection> */}
          <FadeInSection>
            <BrandsGallery 
            vendors={vendors.items} 
            description="Put your trust in the best by diving into our featured brands section. We've personally selected top-tier brands that are synonymous with excellence in the frozen goods industry. Join us in savoring the best in the business and elevate your grocery shopping experience."
            />
          </FadeInSection>
          <Newsletter />
      </div>
    </div>
  );
}

function FeaturedCollection({
  collection,
}: {
  collection: FeaturedCollectionFragment;
}) {
  const image = collection.image;
  return (
    <Link
      className="featured-collection"
      to={`/collections/${collection.handle}`}
    >
      {image && <Image data={image} sizes="100vw" />}
      <h1 className="px-nav">{collection.title}</h1>
    </Link>
  );
}

function RecommendedProducts({
  products,
}: {
  products: Promise<RecommendedProductsQuery>;
}) {
  return (
    <div className="recommended-products">
      <h2>Recommended Products</h2>
      <Suspense fallback={<div>Loading...</div>}>
        <Await resolve={products}>
          {({products}) => (
            <div className="recommended-products-grid">
              {products.nodes.map((product) => (
                <Link
                  key={product.id}
                  className="recommended-product"
                  to={`/products/${product.handle}`}
                >
                  <Image
                    data={product.images.nodes[0]}
                    aspectRatio="1/1"
                    sizes="(min-width: 45em) 20vw, 50vw"
                  />
                  <h4>{product.title}</h4>
                  <small>
                    <Money data={product.priceRange.minVariantPrice} />
                  </small>
                </Link>
              ))}
            </div>
          )}
        </Await>
      </Suspense>
      <br />
    </div>
  );
}

const FEATURED_COLLECTION_QUERY = `#graphql
  fragment FeaturedCollection on Collection {
    id
    title
    image {
      id
      url
      altText
      width
      height
    }
    handle
  }
  query FeaturedCollection($country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
    collections(first: 1, sortKey: UPDATED_AT, reverse: true) {
      nodes {
        ...FeaturedCollection
      }
    }
  }
` as const;

const RECOMMENDED_PRODUCTS_QUERY = `#graphql
  fragment RecommendedProduct on Product {
    id
    title
    handle
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
    }
    images(first: 1) {
      nodes {
        id
        url
        altText
        width
        height
      }
    }
  }
  query RecommendedProducts ($country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
    products(first: 4, sortKey: UPDATED_AT, reverse: true) {
      nodes {
        ...RecommendedProduct
      }
    }
  }
` as const;
